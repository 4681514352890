import polyglotI18nProvider from 'ra-i18n-polyglot';
import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react';
import { Admin, CustomRoutes, resolveBrowserLocale, Resource } from 'react-admin';
import {
  buildAuthProvider, buildDataProvider, CognitoGroupList, CognitoUserList, CognitoUserShow
} from 'react-admin-amplify';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { usePermissions } from 'react-admin';

import awsExports from './aws-exports';
import config from './config';
import ProgramList from './program/ProgramList';
import exercises from './exercises';
import ExerciseList from './exercises/ExerciseList';
import * as mutations from './graphql/mutations';
import * as queries from './graphql/queries';
import englishMessages from './i18n/en';
import frenchMessages from './i18n/fr';
/* With polyglotI18nProvider, we absolutly need to load all translation file synchronously like this */
import incredibleEnglishMessages from './i18n/incredible/en';
import incredibleFrenchMessages from './i18n/incredible/fr';
import kprepEnglishMessages from './i18n/kprep/en';
import kprepFrenchMessages from './i18n/kprep/fr';
import InviteCodeCreate from './inviteCodes/CodeCreate';
import InviteCodesEdit from './inviteCodes/CodeEdit';
import InviteCodesList from './inviteCodes/CodeList';
import InviteCodesShow from './inviteCodes/CodeShow';
import { Layout, Login, Redirect } from './layout';
import { theme } from './layout/themes';
import ProgramShow from './program/ProgramShow';
import sessions from './sessions';
import SessionsGenerator from './sessions/SessionGenerator';
import UsersEdit from './users/UsersEdit';
import UsersList from './users/UsersList';
import UsersShow from './users/UsersShow';

const environment = String(process.env.REACT_APP_ID);
const currentConfig = config[environment];

export interface CustomRoute {
  path: string; // The route path (e.g., "/posts/:id")
  component: ReactElement; // The React component to render for this path
}

Amplify.configure({
  ...awsExports,
  fetchOptions: {
    timeout: 120000,
  },
});

const authProvider = buildAuthProvider({
  authGroups: ["superadmin", "admin", "manager"],
});

const loadMessages = (locale) => {

  const urlPath = window.location.pathname;
  const programId = urlPath.split('/')[1]; // Program

  let programEnglishMessages = {};
  let programFrenchMessages = {};
  switch (programId) {
    case 'incredible':
      programEnglishMessages = incredibleEnglishMessages
      programFrenchMessages = incredibleFrenchMessages
      break;
    case 'kprep':
      programEnglishMessages = kprepEnglishMessages
      programFrenchMessages = kprepFrenchMessages
      break;
  }
  const messages = {
    en: { ...englishMessages, ...programEnglishMessages },
    fr: { ...frenchMessages, ...programFrenchMessages },
  };

  return messages[locale] ? messages[locale] : messages.en
}

const i18nProvider = polyglotI18nProvider(
  loadMessages,
  resolveBrowserLocale()
);

const dataProvider = buildDataProvider(
  {
    queries,
    mutations,
  },
  {
    storageBucket: awsExports.aws_user_files_s3_bucket,
    storageRegion: awsExports.aws_user_files_s3_bucket_region,
    enableAdminQueries: true,
  }
);

// Context to store custom routes
const CustomRoutesContext = createContext<any>([]);

// Custom hook to use routes
export const useCustomRoutes = (): CustomRoute[] => {
  return useContext(CustomRoutesContext);
};

function App() {

  const [customRoutes, setCustomRoutes] = useState<any>([]);

  useEffect(() => {
    const declareRoutes = async () => {
      // Get user's groups
      const user = await Auth.currentAuthenticatedUser();
      const idToken = user.signInUserSession.idToken;
      if (idToken) {

        const groups = idToken.payload['cognito:groups'] || [];

        // Declare all routes, depending on the user's groups
        let customRoutes = [
          { path: "/redirect", component: <Redirect /> },
          { path: "/:programId/home", component: <ProgramShow /> },
          { path: "/:programId/invite-codes", component: <InviteCodesList /> },
          { path: "/:programId/invite-codes/:id/show", component: <InviteCodesShow /> },
          { path: "/:programId/users", component: <UsersList /> },
          { path: "/:programId/users/:id/show", component: <UsersShow /> },
        ];
        if (groups.includes("admin") || groups.includes("superadmin")) {
          customRoutes = [
            ...customRoutes,
            { path: "/sessions/generate", component: <sessions.generate /> },
            { path: "/:programId/edit", component: <></> }, // Program edit route defined only to access to the form (popup).
            { path: "/:programId/invite-codes/create", component: <InviteCodeCreate /> },
            { path: "/:programId/invite-codes/:id/edit", component: <InviteCodesEdit /> },
            { path: "/:programId/users/:id/edit", component: <UsersEdit /> },
            { path: "/:programId/sessions/generate", component: <SessionsGenerator /> },
            { path: "/sessions/generate", component: <SessionsGenerator /> },
            { path: "/:programId/sessions/exercises", component: <ExerciseList /> },
          ]
        }

        setCustomRoutes(customRoutes);
      } else {
        setCustomRoutes([]);
      }
    };

    declareRoutes();
  }, []);


  return (
    <Router>
      <CustomRoutesContext.Provider value={customRoutes}>
        <Admin
          authProvider={authProvider}
          dataProvider={dataProvider}
          theme={theme}
          i18nProvider={i18nProvider}
          disableTelemetry
          loginPage={Login}
          layout={Layout}
          dashboard={ProgramList}
          title={"Alix"}
        >
          {(permissions) => [
            <Resource name={currentConfig.resources.exercises} {...exercises} />,
            <CustomRoutes noLayout>
              <Route path="/" element={<ProgramList />} />,
            </CustomRoutes>,
            <CustomRoutes>
              {customRoutes.map((route, index) => (
                <Route key={index} path={route.path} element={route.component} />
              ))}
            </CustomRoutes>,
            permissions.includes("superadmin") ? (
              <Resource
                name="cognitoUsers"
                options={{ label: "Cognito Users" }}
                list={CognitoUserList}
                show={CognitoUserShow}
              />
            ) : null,
            permissions.includes("superadmin") ? (
              <Resource
                name="cognitoGroups"
                options={{ label: "Cognito Groups" }}
                list={CognitoGroupList}
              />
            ) : null,
          ]}
        </Admin>
      </CustomRoutesContext.Provider>
    </Router>
  );
}

export default App;
