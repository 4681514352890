import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Box,
  TextField
} from '@mui/material';
import { useTranslate } from "ra-core";
import { useRedirect, useI18nProvider } from 'react-admin';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import useRouteExists from "../utils/useRouteExists";

const MAX_ITEMS_BEFORE_SHOW_ALL = 3
export default function FilteredList(props) {
  let navigate = useNavigate()
  const translate = useTranslate();
  const routeExists = useRouteExists();
  const [showAll, setShowAll] = useState(false)
  const [filtersValue, setFiltersValue] = useState({})
  const filtersToDisplay = props.displayAttributes.filter((a) => a.filter)
  const i18nProvider = useI18nProvider()

  const filterFunctions = filtersToDisplay.map((f) => {
    switch (f.filter) {
      case "exact":
        return (a) => !filtersValue[f.key] || (a[f.key] && a[f.key] === filtersValue[f.key])
      case "stringStart":
        return (a) => !filtersValue[f.key] || (a[f.key] && a[f.key].toLowerCase().startsWith(filtersValue[f.key].toLowerCase()))
    }
  })
  let filteredData = filterFunctions.reduce((current, filter) => {
    return current.filter(filter);
  }, props.data);

  let dataToDisplay = filteredData
  if (!showAll) {
    dataToDisplay = dataToDisplay.slice(0, MAX_ITEMS_BEFORE_SHOW_ALL)
  }

  const handleDetails = (id) => {
    navigate(`${props.path}/${id}/show`)
  }
  const handleEdit = (id) => {
    navigate(`${props.path}/${id}/edit`)
  }

  const renderAttribute = (att, item) => {
    switch (att.type) {
      case "String":
        return (
          <TableCell key={att.key}>{item[att.key]}</TableCell>
        )
      case "moment": {
        return (
          <TableCell key={att.key}>{item[att.key] ? moment(item[att.key]).locale(i18nProvider.getLocale()).fromNow() : ""}</TableCell>
        )
      }
    }
  }

  const handleChange = (e, key) => {
    setFiltersValue({ ...filtersValue, [key]: e.target.value })
  }

  const renderFilter = (filter) => {
    return (
      <TextField
        key={filter.key}
        label={filter.name}
        variant="outlined"
        value={filtersValue[filter.key]}
        onChange={(e) => handleChange(e, filter.key)}
        margin="normal"
      />
    )
  }

  return (
    <>
      <Box display="flex" flexDirection="row">
        {filtersToDisplay.map((filter) => renderFilter(filter))}
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            {props.displayAttributes.map(((att, index) => {
              return (<TableCell key={index}>{att.name}</TableCell>)
            }))}
            <TableCell>
            </TableCell>
            <TableCell>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataToDisplay.map((item, index) => {
            return (
              <TableRow key={index}>
                {props.displayAttributes.map(att => renderAttribute(att, item))}
                <TableCell>
                  {routeExists(`${props.path}/${item.id}/show`) ?
                    <Button variant="contained" onClick={() => handleDetails(item.id)}>
                      {translate('resources.misc.details')}
                    </Button>
                    : null
                  }
                </TableCell>
                <TableCell>
                  {routeExists(`${props.path}/${item.id}/edit`) ?
                    <Button variant="contained" onClick={() => handleEdit(item.id)}>
                      {translate('resources.misc.edit')}
                    </Button>
                    : null
                  }
                </TableCell>

              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {
        filteredData.length > 3 &&
        <Button variant="outlined" onClick={() => setShowAll(!showAll)}>
          {showAll ? "Afficher moins" : "Afficher tout"}
        </Button>
      }

    </>
  );
}