import React, { useEffect, useState } from "react";
import { useTranslate } from 'ra-core';
import { SortPayload, useGetList, useRedirect } from 'react-admin';

import { Button, CircularProgress, Grid, Stack } from '@mui/material';
import ProgramForm from '../program/ProgramForm';
import ProgramDisplay from '../program/ProgramDisplay';
import { useFetchData } from '../utils/useAdminApi';
import { ProgramModel } from "./model";

export default function ProgramList(props) {
  const translate = useTranslate();
  const redirect = useRedirect();

  const { data, loading, error } = useFetchData("/adminApi/program") as { data: ProgramModel[], loading: boolean, error: any };

  if (loading || !data) { return <CircularProgress size={25} thickness={2} />; }

  if(data.length == 1) {
    // If the user has access to only one program, redirect to it.
    redirect(`/${data[0].id}/home`);
  }

  return (
    <>
      <Grid
        justifyContent="center"
        alignItems="flex-start"
        container
        sx={{ minHeight: '84vh', maxHeight: "84vh" }}
        overflow='scroll'
      >
        <Grid item lg={8}>
          <Stack
            spacing={2}
            direction="column"
            sx={{ mx: 4, mt: 4, mb: 4 }}
          >
            {data ? data.map((program) => (
              <ProgramDisplay data={program} key={program.id} />
            )) : null}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
